@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    /* Light theme */
    --background: #f1f1f1;
    --text: #333333;
    --primary: #007bff;
    --secondary-bg: #f8f9fa;
    --card-bg: #ffffff;
    --border-color: #dee2e6;
    --shadow: rgba(0, 0, 0, 0.1);
    --navbar-bg: #ffffff;
    --button-hover: #E6F0FF;
  }

  [data-theme="dark"] {
    /* Dark theme */
    --background: #252525;
    --text: #ffffff;
    --primary: #4da3ff;
    --secondary-bg: #1e1e1e;
    --card-bg: #424242;
    --border-color: #404040;
    --shadow: rgba(0, 0, 0, 0.3);
    --navbar-bg: #2A2A2A;
    --button-hover: #273d57;
  }

/* Apply these variables to your elements */
body {
  margin: 0;
  background-color: var(--background);
  color: var(--text);
  transition: all 0.3s ease;
}

.App {
  min-height: 100vh;
  background-color: var(--background);
  color: var(--text);
}

/* Example styles for common elements */
h1, h2, h3, h4, h5, h6 {
  color: var(--text);
}

a {
  color: var(--primary);
}

/* Your theme toggle styles */
.theme-toggle {
  position: fixed;
  top: 1rem;
  right: 1rem;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid var(--primary);
  background: var(--background);
  color: var(--text);
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  box-shadow: 0 2px 5px rgba(0,0,0,0.2);
}

.theme-toggle:hover {
  transform: scale(1.1);
  box-shadow: 0 3px 8px rgba(0,0,0,0.3);
}

.theme-toggle:active {
  transform: scale(0.95);
}

